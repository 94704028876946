import React from 'react';
import axios from 'axios';
import {
  HashRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import HomeComponent from './Home';
import FacesComponent from './Faces';
import DetailModal from './DetailModal';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemToShow: null,
      faceIds: null,
      faceItemToSearch: null
    }
  }

  openDetailModal = (itemToShow, faceIds = null) => {
    this.setState({
      itemToShow: itemToShow,
      faceIds: faceIds
    });
  }

  closeDetailModal = () => {
    this.setState({
      itemToShow: null
    });
  }

  findFace = (faceItem) => {
    this.closeDetailModal();
    axios.get(
      window.gData.esUrl + window.gData.facesIndexName + '/_doc/' + faceItem.id
      ).then((response) => {
        this.setState({
          faceItemToSearch: response.data._source
        });
        window.setTimeout(() => {
          document.getElementsByClassName('link-faces')[0].click();
        }, 100);
    }).catch((error) => {
      console.warn(error);
    });
  }

  faceItemSearched = () => {
    this.setState({
      faceItemToSearch: null
    });
  }

  render() {
    return (
      <Router>
        <div className="main-nav">
          <nav>
            <ul>
              <li>
                <NavLink exact to="/" activeClassName="active">Standardsuche</NavLink>
              </li>
              <li>
                <NavLink className="link-faces" exact to="/faces" activeClassName="active">Suche nach Gesichtern</NavLink>
              </li>
            </ul>
          </nav>
          <Switch>
            <Route exact component={App} path="/faces">
              <FacesComponent openModal={this.openDetailModal} faceItemToSearch={this.state.faceItemToSearch} faceItemSearched={this.faceItemSearched} />
            </Route>
            <Route exact component={App} path="/">
              <HomeComponent openModal={this.openDetailModal} />
            </Route>
          </Switch>
        </div>
        {this.state.itemToShow != null && 
          <DetailModal item={this.state.itemToShow} openModal={this.openDetailModal} closeModal={this.closeDetailModal} faceIds={this.state.faceIds} findFace={this.findFace}>
          </DetailModal>
        }
      </Router>
    );
  }
}

export default App;