import React, { Component } from 'react';
import lodash from 'lodash';
import {
  ReactiveBase,
  ReactiveComponent,
  DataSearch,
  ReactiveList,
  ResultCard,
  DynamicRangeSlider,
  MultiDropdownList,
  SelectedFilters
} from '@appbaseio/reactivesearch';
import ColorPicker from './ColorPicker';
import ImagePicker from './ImagePicker';
import axios from 'axios';

class HomeComponent extends Component {
  constructor(props) {
    super(props);

    this.reactIds = [
      'SearchBox',
      'ColorPickerFilter',
      'ImagePickerFilter',
      'DateSlider',
      'SubjectList',
      'LanguageList',
      'CreatorList',
      'LocationList',
      'PhysicalDescriptionList',
      'PersonsList',
      'CompaniesList',
      'ObjectsList'
    ];

    this.state = {
      color: '#ffffff',
      tabKey: 'image',
      featureImage: null,
      features: null
    }

    this.maxScore = 1;
  }
  
  renderListItem(label, count, isSelected) {
    return (
      <div>
        <span>{label}</span>{count &&
          <span className="filter-count">({count})</span>
        }
      </div>
    );
  }

  handleTopColorClick = (e, topColor) => {
    e.stopPropagation();
    this.setState({
      color: topColor,
      tabKey: 'color'
    });
  }

  handleImageSearchClick = (e, id) => {
    e.stopPropagation();
    axios.get(
      window.gData.esUrl + window.gData.esIndexName + '/_doc/' + id
    ).then((response) => {
      if (!response.data._source.imgFeatures) {
        return;
      }

      this.setState({
        tabKey: 'image',
        features: response.data._source.imgFeatures,
        featureImage: response.data._source.image.url
      });
    }).catch((error) => {
      console.warn(error);
    });
  }

  updateColorPicker = (topColor, useColorPicker) => {
    this.setState({
      color: topColor,
      tabKey: 'color'
    })
  }

  updateImagePicker = (featureImage, features) => {
    this.setState({
       featureImage: featureImage,
       features: features
    });
  }

  changeTab(tabKey) {
    this.setState({ tabKey: tabKey });


  }

  render() {
    return (
      <div>
        <ReactiveBase
          url={window.gData.esUrl}
          app={window.gData.esIndexName}
          transformResponse={async (response, componentId) => {
            if (componentId === 'SearchResults') {
              this.maxScore = Math.max(response.hits.max_score, 1);
            }

            return response;
          }}
          >
          <div className="row reverse-labels">
            <div className="col">
              <h2 className="filters-header">Filter</h2>
              <div>
                <MultiDropdownList
                  componentId="LanguageList"
                  dataField="language"
                  title="Sprachen"
                  renderItem={this.renderListItem}
                  size={10}
                  showCount={true}
                  placeholder="Sprachen auswählen"
                  selectAllLabel="Alle Sprachen"
                  react={{
                    and: lodash.without(this.reactIds, 'LanguageList')
                  }}
                  showFilter={true}
                  filterLabel="Sprachen"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-1">
                <MultiDropdownList
                  componentId="CreatorList"
                  dataField="creation.creatorLocations.creator.facet"
                  title="Verlage"
                  renderItem={this.renderListItem}
                  size={100}
                  showCount={true}
                  placeholder="Verlage auswählen"
                  selectAllLabel="Alle Verlage"
                  react={{
                    and: lodash.without(this.reactIds, 'CreatorList')
                  }}
                  showFilter={true}
                  filterLabel="Verlage"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-1">
                <MultiDropdownList
                  componentId="LocationList"
                  dataField="creation.creatorLocations.location.facet"
                  title="Verlagsorte"
                  renderItem={this.renderListItem}
                  size={100}
                  showCount={true}
                  placeholder="Orte auswählen"
                  selectAllLabel="Alle Orte"
                  react={{
                    and: lodash.without(this.reactIds, 'LocationList')
                  }}
                  showFilter={true}
                  filterLabel="Verlagsorte"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-1">
                <MultiDropdownList
                  componentId="SubjectList"
                  dataField="subjects.facet"
                  title="Themen"
                  renderItem={this.renderListItem}
                  size={100}
                  showCount={true}
                  placeholder="Themen auswählen"
                  selectAllLabel="Alle Themen"
                  react={{
                    and: lodash.without(this.reactIds, 'SubjectList')
                  }}
                  showFilter={true}
                  filterLabel="Themen"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-1">
                <MultiDropdownList
                  componentId="PhysicalDescriptionList"
                  dataField="physicalDescription.facet"
                  title="Formate"
                  renderItem={this.renderListItem}
                  size={100}
                  showCount={true}
                  placeholder="Formate auswählen"
                  selectAllLabel="Alle Fromate"
                  react={{
                    and: lodash.without(this.reactIds, 'PhysicalDescriptionList')
                  }}
                  showFilter={true}
                  filterLabel="Formate"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-1">
                <MultiDropdownList
                  componentId="PersonsList"
                  dataField="personsFacet"
                  title="Personen"
                  renderItem={this.renderListItem}
                  size={100}
                  showCount={true}
                  placeholder="Personen auswählen"
                  selectAllLabel="Alle Personen"
                  react={{
                    and: lodash.without(this.reactIds, 'PersonsList')
                  }}
                  showFilter={true}
                  filterLabel="Personen"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-1">
                <MultiDropdownList
                  componentId="CompaniesList"
                  dataField="companiesFacet"
                  title="Organisationen"
                  renderItem={this.renderListItem}
                  size={100}
                  showCount={true}
                  placeholder="Organisationen auswählen"
                  selectAllLabel="Alle Organisationen"
                  react={{
                    and: lodash.without(this.reactIds, 'CompaniesList')
                  }}
                  showFilter={true}
                  filterLabel="Organisationen"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-1">
              	<MultiDropdownList
                  componentId="ObjectsList"
                  dataField="objects.label.facet"
                  title="Objekte"
                  renderItem={this.renderListItem}
                  size={100}
                  showCount={true}
                  placeholder="Objekte auswählen"
                  selectAllLabel="Alle Objekte"
                  react={{
                    and: lodash.without(this.reactIds, 'ObjectsList')
                  }}
                  showFilter={true}
                  filterLabel="Objekte"
                  URLParams={false}
                  loader="Lade ..."
                />
              </div>
              <div className="mt-3">
                <DynamicRangeSlider
                  componentId="DateSlider"
                  dataField="creation.date"
                  title="Datierung (Jahr)"
                  stepValue={1}
                  showHistogram={true}
                  showFilter={true}
                  filterLabel="Jahr"
                  interval={2}
                  react={{
                    and: lodash.without(this.reactIds, 'DateSlider')
                  }}
                  URLParams={false}
                  loader="Lade ..."
                  includeNullValues
                  tooltipTrigger='always'
                  renderTooltipData={data => (
                    <strong>{data}</strong>
                  )}
                />
              </div>
            </div>
            <div className="col" style={{ backgroundColor: '#fafafa' }}>
              <div className="tab-view">
                <div className="tabs">
                  <div
                    className={this.state.tabKey === 'text' ? 'active' : ''}
                    onClick={ () => this.changeTab('text') }
                  >Textsuche</div>
                  <div
                    className={this.state.tabKey === 'color' ? 'active' : ''}
                    onClick={ () => this.changeTab('color') }
                  >Farbsuche</div>
                  <div
                    className={this.state.tabKey === 'image' ? 'active' : ''}
                    onClick={ () => this.changeTab('image') }
                  >Bildsuche</div>
                </div>
                <div className="tab-container">
                  {this.state.tabKey === 'text' &&
                    <div>
                      <DataSearch
                        componentId="SearchBox"
                        dataField={["title", "contentDescription", "id", "mainPersonsSearch", "mainCompaniesSearch", "contributingPersonsSearch", "contributingCompaniesSearch", "responsibility", "subjectGroup", "signaturesSearch", "creatorLocationsSearch", "creationDateSearch", "ocrText", "objects.label"]}
                        fieldWeights={[2,1,1,1,1,1,1,1,1,1,1,1.5]}
                        searchOperators={true}
                        placeholder="Suche in Titel, Angaben zum Inhalt, Personen, Organisationen, Verantwortlich, Themen, Verlage, Datierung, Signatur, ID-Nr, OCR Text, Objekten"
                        autosuggest={false}
                        highlight={true}
                        customHighlight={props => ({
                          highlight: {
                            pre_tags: ['[mark]'],
                            post_tags: ['[/mark]'],
                            fields: {
                              title:{},
                              contentDescription:{},
                              id:{},
                              mainPersonsSearch:{},
                              mainCompaniesSearch:{},
                              contributingPersonsSearch:{},
                              contributingCompaniesSearch:{},
                              responsibility:{},
                              subjectGroup:{},
                              signaturesSearch:{},
                              creatorLocationsSearch:{},
                              ocrText:{}
                            },
                            number_of_fragments: 0
                          }
                        })}
                        debounce={300}
                        react={{
                          and: lodash.without(this.reactIds, 'SearchBox'),
                        }}
                        showClear={true}
                      />
                    </div>
                  }
                  {this.state.tabKey === 'color' &&
                    <div>
                      <ReactiveComponent
                        componentId="ColorPickerFilter"
                        filterLabel="Farbe"
                        defaultQuery={() => ({})}
                        render = {({ aggregations, setQuery }) => (
                          <ColorPicker
                            color={this.state.color}
                            useColorPicker={true}
                            useCheckbox={false}
                            updateColorPicker={this.updateColorPicker}
                            aggregations = {aggregations}
                            setQuery = {setQuery}
                          />
                        )}
                      />
                    </div>
                  }
                  {this.state.tabKey === 'image' &&
                    <div>
                      <ReactiveComponent
                        componentId="ImagePickerFilter"
                        filterLabel="Bildsuche"
                        defaultQuery={() => ({})}
                        render = {({ aggregations, setQuery }) => (
                          <ImagePicker
                            featureImage={this.state.featureImage}
                            features={this.state.features}
                            updateImagePicker={this.updateImagePicker}
                            aggregations = {aggregations}
                            setQuery = {setQuery}
                          />
                        )}
                      />
                    </div>
                  }
                </div>
              </div>
              <div className="mt-1">
                <SelectedFilters
                  render={(props) => {
                    const { selectedValues, setValue } = props;
                    const clearFilter = (component) => {
                      setValue(component, null);
                    };
                    let filterCount = 0;

                    const filters = Object.keys(selectedValues).map((component) => {
                      if (!selectedValues[component].value || selectedValues[component].value.length === 0) return null;
                      let selectedValue = selectedValues[component];
                      
                      if (component !== 'ColorPickerFilter') {
                        filterCount++;
                      }

                      if (component === 'ImagePickerFilter') {
                        return (
                          <button
                            className="selected-filter"
                            key={component}
                            onClick={
                              () => {
                                clearFilter(component)
                                this.updateImagePicker(null, null);
                              }
                            }
                          >
                            <span className="label">{selectedValue.label}</span><span className="clear-icon"><span className="clear-icon-small"></span></span>
                          </button>
                        );
                      } else if (component !== 'ColorPickerFilter') {
                        let value = selectedValue.value;
                        if (lodash.isArray(value)) {
                          value = value.join(', ');
                        }
                        return (
                          <button
                            className="selected-filter"
                            key={component}
                            onClick={() => clearFilter(component)}
                          >
                            <span className="label">{selectedValue.label}:</span><span className="value">{value}</span><span className="clear-icon"><span className="clear-icon-small"></span></span>
                          </button>
                        );
                      } else {
                      	return null;
                      }              
                    });

                    if (filterCount > 0) {
                      filters.push(
                        <button
                            className="selected-filter"
                            key="clear-all"
                            onClick={
                              () => {
                                props.clearValues();
                              }
                            }
                          >
                            Alle löschen
                          </button>
                      );
                    }

                    return filters;
                  }}
                  />
              </div>
              <ReactiveList
                componentId="SearchResults"
                dataField="title"
                size={50}
                pagination={true}
                react={{
                  and: this.reactIds
                }}
                onQueryChange={
                  function(prevQuery, nextQuery) {
                    nextQuery.track_total_hits = 200000;
                    nextQuery._source = { excludes: [ 'imgFeatures' ] }
                  }
                }
                sortOptions={[
                  {label: 'Relevanz (absteigend)', dataField: '_score', sortBy: 'desc'},
                  {label: 'Titel (aufsteigend)', dataField: 'title.sort', sortBy: 'asc'},
                  {label: 'Titel (absteigend)', dataField: 'title.sort', sortBy: 'desc'},
                  {label: 'Jahr (aufsteigend)', dataField: 'creation.date', sortBy: 'asc'},
                  {label: 'Jahr (absteigend)', dataField: 'creation.date', sortBy: 'desc'}
                ]}
                render={({ data, error, loading, promotedData }) => {
                  return (
                    <ReactiveList.ResultCardsWrapper>
                      { 
                        data.map(item => (
                          <ResultCard
                            key={item._id}
                            onClick={(e) => {
                              this.props.openModal(item);
                            }}
                            >
                            <ResultCard.Image src={(item.thumbnail && item.thumbnail.url)?("img/thumbs/" + item.thumbnail.url.substring(item.thumbnail.url.lastIndexOf('/') + 1)):"img/no-thumb.png"}/>
                            <ResultCard.Title className="card-title-ellipsis" title={item.title.replace(/(\[mark\]|(\[\/mark\]))/gi, '')}
                                dangerouslySetInnerHTML={{
                                  __html: lodash.escape(item.title).replaceAll('[mark]','<mark>').replaceAll('[/mark]','</mark>')
                                }}
                            />
                            <ResultCard.Description>
                                <div>
                                    {this.state.tabKey === 'text' && item._score && this.maxScore > 1 &&
                                      <div className="score-item" title={'Score: ' + item._score}><div style={{width: (item._score / this.maxScore * 100) + '%'}}></div></div>
                                    }
                                    {this.state.tabKey === 'color' && item._score &&
                                      <div className="score-item" title={'Score: ' + item._score}><div style={{width: (item._score * 100) + '%'}}></div></div>
                                    }
                                    {this.state.tabKey === 'image' && this.state.featureImage && item._score &&
                                      <div className="score-item" title={'Score: ' + item._score}><div style={{width: (item._score * 100) + '%'}}></div></div>
                                    }
                                    <div style={{display: 'flex'}}>
                                      <div style={{flex:'1'}}>{item.creation.date}</div>
                                      <div className="result-colors-and-image">
                                        <div
                                          className="button-image-search"
                                          onClick={(e) => {
                                            this.handleImageSearchClick(e, item.id)
                                          }}
                                          title="Nach ähnlichen Bildern suchen"
                                        ><span></span></div>
                                        <div
                                          className="color-button"
                                          style={{backgroundColor: item.topColorRgbHex}}
                                          onClick={(e) => {
                                            this.handleTopColorClick(e, item.topColorRgbHex);
                                          }}
                                          title={'Nach Farbe ' +  item.topColorRgbHex + ' filtern'}></div>
                                        <div
                                          className="color-button"
                                          style={{backgroundColor: item.topColor2RgbHex}}
                                          onClick={(e) => {
                                            this.handleTopColorClick(e, item.topColor2RgbHex);
                                          }}
                                          title={'Nach Farbe ' +  item.topColor2RgbHex + ' filtern'}></div>
                                      </div>
                                    </div>
                                    { item.physicalDescription &&
                                     <div>{item.physicalDescription.join('; ')}</div>
                                    }
                                </div>
                            </ResultCard.Description>
                        </ResultCard>
                        ))
                      }
                    </ReactiveList.ResultCardsWrapper>
                  )
                }}
                renderNoResults={() => (
                  <div>Keine Ergebnisse gefunden</div>
                )}
              />
            </div>
          </div>
        </ReactiveBase>
      </div>
    );
  }
}

export default HomeComponent;
