import React, { Component } from 'react';
import axios from 'axios';

class ImagePicker extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      uploading: false,
      features: props.features,
      imageSrc: props.featureImage
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    this.requestImage(this.state.features, null, this.state.imageSrc);
  }

  onFileChange(e) {
    if (!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];
    const formData = new FormData();

    formData.append(
      'file',
      file,
      file.name
    );

    this.setState({
      uploading: true,
      image: null,
      features: null,
      imageSrc: null
    });

    axios.post(
      window.gData.imgFeaturesUrl,
      formData
    ).then((response) => {
      if (response.data.features.length === 0) {
        this.setState({
          uploading: false,
          features: null,
          image: null,
          imageSrc: null
        });
        return;
      }

      this.setState({
        uploading: false
      });

      var features = this.normalizeFeatures(response.data.features);

      this.setState({
        features: features,
        image: file,
        imageSrc: null
      });

      this.requestImage(features, file);
    }).catch((error) => {
      this.setState({
        uploading: false,
        features: null,
        image: null,
        imageSrc: null
      });
    });

    e.target.value = '';
  }

  normalizeFeatures(vector) {
    if (vector.length === 0) {
      return [];
    }

    var sum = 0;
    var result = [];

    for (let i = 0; i < vector.length; i++) {
      sum += vector[i] * vector[i];
    }

    var length = Math.sqrt(sum);

    for (let i = 0; i < vector.length; i++) {
      result.push(vector[i] / length);
    }

    return result;
  }

  createImageSrc(image, callback) {
    if (!FileReader) {
      return;
    }

    var fileReader = new FileReader();
    var _this = this;

    fileReader.onload = function() {
      _this.setState({
        imageSrc: fileReader.result
      });
      callback(fileReader.result);
    }

    fileReader.readAsDataURL(image);
  }

  requestImage(features, image, imageSrc) {
    var query = {
      query: {
        "match_all": {}
      },
      value: ''
    };

    var value = null;

    if (features && features.length > 0) {
      query = {
        query: {
          knn: {
            imgFeatures: {
              k: 300,
              vector: features
            }
          }
        }
      };

      value = 'Image';
    }

    if (image) {
      var _this = this;
      this.createImageSrc(image, function(newImageSrc) {
        _this.props.updateImagePicker(newImageSrc, features);
      });
    } else {
      this.props.updateImagePicker(imageSrc, features);
    }

    this.props.setQuery({
      query,
      value: value
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.featureImage === prevProps.featureImage && this.props.features === prevProps.features) {
      return;
    }

    this.setState({
      imageSrc: this.props.featureImage,
      features: this.props.features
    });
    
    this.requestImage(this.props.features, null, this.props.featureImage);
  }

  render() {
    return (
      <div>
        {this.state.imageSrc &&
          <img className="feature-image" src={this.state.imageSrc} alt="" />
        }
        <div className="file-upload-wrapper">
          <input type="file" onChange={this.onFileChange} />
          <button type="button"><div className={'lds-facebook' + (this.state.uploading ? ' active' : ' inactive')}><div></div><div></div><div></div></div><span>Bild hochladen</span></button>
        </div>
      </div>
    );
  }
}

export default ImagePicker;