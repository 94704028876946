import React from "react";
import lodash from 'lodash';
import axios from 'axios';
import {
  ReactiveBase,
  ReactiveList,
  ResultCard,
} from '@appbaseio/reactivesearch';

class FacesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      uploaded: false,
      hasResults: false,
      hasError: false,
      hasEmptyResponse: false
    };

    this.facesQuery = {};
    this.posterIds = [];
    this.idsToFaceIds = null;

    this.macScore = 0;

    this.onFileChange = this.onFileChange.bind(this);

    if (this.props.faceItemToSearch) {
      this.requestFaces([this.props.faceItemToSearch]);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.faceItemToSearch || prevProps.faceItemToSearch === this.props.faceItemToSearch) {
      return;
    }

    this.setState({
      uploading: false,
      uploaded: false,
      hasResults: false,
      hasError: false,
      hasEmptyResponse: false
    });

    this.requestFaces([this.props.faceItemToSearch]);
  }

  onFileChange(e) {
    if (!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];
    const formData = new FormData();
    
    formData.append(
      'file',
      file,
      file.name
    );

    this.setState({
      uploading: true
    });

    axios.post(
      window.gData.facesUrl,
      formData
    ).then((response) => {
      if (response.data.faces.length === 0) {
        this.setState({
          uploading: false,
          hasResults: false,
          hasError: false,
          hasEmptyResponse: true
        });
        return;
      }
      this.requestFaces(response.data.faces);
    }).catch((error) => {
      console.warn(error);
      this.setState({
        uploading: false,
        hasResults: false,
        hasError: true,
        hasEmptyResponse: false
      });
    });
  }

  requestFaces(faces) {
    const data = {
      query: {
        knn: {
          features: {
            k: 10000,
            vector: faces[0].features
          }
        }
      }
    };

    axios.post(
      window.gData.esUrl + window.gData.facesIndexName + '/_search',
      data
    ).then((response) => {
      this.setState({
        uploading: false
      });
      this.showResults(response, this.props.faceItemToSearch);
      this.props.faceItemSearched();
    }).catch((error) => {
      console.warn(error);
      this.setState({
        uploading: false
      });
    });
  }

  showResults(response, faceItemToSearch) {
    const hits = response.data.hits.hits;
    this.maxScore = response.data.hits.max_score;

    if (faceItemToSearch) {
      var index = lodash.findIndex(hits, (hit) => {
        return hit._id === faceItemToSearch.id;
      });

      if (index > -1) {
        hits.splice(index, 1);
      }
    }

    if (hits.length === 0) {
      this.setState({
        uploaded: true,
        hasResults: false,
        hasEmptyResponse: true
      });
    } else {
      this.posterIds = hits.map((hit) => {
        return {
          posterId: hit._source.posterId,
          score: hit._score
        }
      });
      this.idsToFaceIds = {};

      for (let hit of hits) {
        if (!this.idsToFaceIds[hit._source.posterId]) {
          this.idsToFaceIds[hit._source.posterId] = [];
        }

        this.idsToFaceIds[hit._source.posterId].push(hit._id); 
      }

      this.facesQuery = {
        query: {
          terms: {
            _id: this.posterIds.map((item) => { return item.posterId; })
          }
        }
      };
      this.setState({
        uploaded: true,
        hasResults: true,
        hasEmptyResponse: false,
        hasError: false
      });
    }
  }

  render() {
    const { faceItemToSearch } = this.props;

    return (
      <div className="faces-view">
        {this.state.hasEmptyResponse &&
          <div class="info-box info-box-warning">Es konnten keine passenden Plakate anhand des hochgeladenen Bildes gefunden werden.</div>
        }
        {this.state.hasError &&
          <div class="info-box info-box-error">Bei der Verarbeitung des hochgeladenen Bildes ist ein Fehler aufgetreten.</div>
        }
        {!this.state.hasResults && !faceItemToSearch &&
          <div className="faces-file-upload-wrapper">
            <div className="file-upload-wrapper">
              <input type="file" onChange={this.onFileChange} />
              <button type="button"><div className={'lds-facebook' + (this.state.uploading ? ' active' : ' inactive')}><div></div><div></div><div></div></div><span>Bild hochladen</span></button>
            </div>
          </div>
        }
        {this.state.uploaded && this.state.hasResults &&
          <ReactiveBase
            url={window.gData.esUrl}
            app={window.gData.esIndexName}
            transformResponse={async (response, componentId) => {
              if (componentId !== 'FacesResults') {
                return response;
              }

              const sortedHits = [];
              const foundHitIds = [];

              for (let posterItem of this.posterIds) {
                let foundHit = lodash.find(response.hits.hits, (hit) => {
                  return hit._id === posterItem.posterId;
                });

                if (foundHit && lodash.indexOf(foundHitIds, foundHit._id) === -1) {
                  foundHitIds.push(foundHit._id);
                  foundHit._source.faceScore = posterItem.score;
                  sortedHits.push(foundHit);
                }
              }

              response.hits.hits = sortedHits;

              return response;
            }}>
            <button
              className="button-back"
              onClick={() => {
                this.setState({
                  uploaded: false,
                  uploading: false,
                  hasResults: false
                });
              }}
              >
                Zurück zum Upload
            </button>
            <ReactiveList
              componentId="FacesResults"
              dataField="title"
              size={100}
              pagination={false}
              defaultQuery={() => this.facesQuery}
              render={({ data, error, loading }) => (
                <ReactiveList.ResultCardsWrapper>
                  {
                    data.map(item => (
                      <ResultCard
                        key={item._id}
                        onClick={(e) => {
                          this.props.openModal(item, this.idsToFaceIds[item._id]);
                        }}
                        >
                        <ResultCard.Image src={(item.thumbnail && item.thumbnail.url)?("img/thumbs/" + item.thumbnail.url.substring(item.thumbnail.url.lastIndexOf('/') + 1)):"img/no-thumb.png"}/>
                        <ResultCard.Title className="card-title-ellipsis" title={item.title.replace(/(\[mark\]|(\[\/mark\]))/gi, '')}
                            dangerouslySetInnerHTML={{
                              __html: lodash.escape(item.title).replaceAll('[mark]','<mark>').replaceAll('[/mark]','</mark>')
                            }}
                        />
                        <ResultCard.Description>
                            <div>
                                <div className="score-item" title={'Score: ' + item.faceScore}><div style={{width: (item.faceScore / this.maxScore * 100) + '%'}}></div></div>
                                <div style={{display: 'flex'}}>
                                <div style={{flex:'1'}}>{item.creation.date}</div>
                                  <div className="result-colors">
                                    <div
                                      style={{backgroundColor: item.topColorRgbHex}}
                                      onClick={(e) => {
                                        this.handleTopColorClick(e, item.topColorRgbHex);
                                      }}
                                      title={'Nach Farbe ' +  item.topColorRgbHex + ' filtern'}></div>
                                    <div
                                      style={{backgroundColor: item.topColor2RgbHex}}
                                      onClick={(e) => {
                                        this.handleTopColorClick(e, item.topColor2RgbHex);
                                      }}
                                      title={'Nach Farbe ' +  item.topColor2RgbHex + ' filtern'}></div>
                                  </div>
                                </div>
                                { item.physicalDescription &&
                                 <div>{item.physicalDescription.join('; ')}</div>
                                }
                            </div>
                        </ResultCard.Description>
                    </ResultCard>
                    ))
                  }
                </ReactiveList.ResultCardsWrapper>
              )}
              renderNoResults={() => (
                <div>Keine Ergebnisse gefunden</div>
              )}
            />
          </ReactiveBase>
        }
      </div>
    );
  }
}

export default FacesComponent;