import React, { Component } from 'react';
import {
  HexColorPicker,
  HexColorInput
} from 'react-colorful';
import colorSpace from 'color-space';
import hexRgb from 'hex-rgb';
import debounce from 'lodash.debounce';
import axios from 'axios';

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      useColorPicker: props.useColorPicker ? props.useColorPicker : false,
      color: props.color ? props.color : '#ffffff',
      uploading: false
    };

    this.handleUseColorPickerChange = this.handleUseColorPickerChange.bind(this);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.useCheckbox = props.useCheckbox !== false;
  }

  componentDidMount() {
    this.buildQuery({useColorPicker: this.props.useColorPicker});
  }

  handleUseColorPickerChange(event) {
    if (this.props.updateColorPicker) {
      this.props.updateColorPicker(this.state.color, event.target.checked);
    }

    this.setState({
      useColorPicker: event.target.checked
    });
    this.buildQuery({useColorPicker: event.target.checked});
  }

  debouncedHandleChangeColor = debounce(color => {
    if (this.props.updateColorPicker) {
      this.props.updateColorPicker(color.color, this.state.useColorPicker);
    }

    this.setState(color);
    this.buildQuery(color);
  }, 200);

  componentDidUpdate(prevProps) {
    if (prevProps.color === this.props.color && prevProps.useColorPicker === this.props.useColorPicker) {
      return;
    }

    const props = {
      color: this.props.color,
      useColorPicker: this.props.useColorPicker
    };

    this.setState(props);
    this.buildQuery(props);
  }

  handleChangeColor(color) {
    this.debouncedHandleChangeColor({color: color});
  }

  buildQuery(props) {
    if (typeof props.useColorPicker === 'undefined') {
      props.useColorPicker = this.state.useColorPicker;
    }

    if (typeof props.color === 'undefined') {
      props.color = this.state.color;
    }

    if (props.useColorPicker) {
      const rgb = hexRgb(props.color);
      const labColor = colorSpace.rgb.lab([
        rgb.red,
        rgb.green,
        rgb.blue
      ]);

      const query = {
        query: {
          bool: {
            should: [
              {
                function_score: {
                  query: {
                    knn: {
                      topColorLab: {
                        vector: labColor,
                        k: 1000
                      }
                    }
                  },
                  field_value_factor: {
                    field: "topColorWeight",
                    missing: 0
                  }
                }
              },
              {
                function_score: {
                  query: {
                    knn: {
                      topColor2Lab: {
                        vector: labColor,
                        k: 1000
                      }
                    }
                  },
                  field_value_factor: {
                    field: "topColor2Weight",
                    missing: 0
                  }
                }
              }
            ]
          }
        }
      };

      this.props.setQuery({
        query,
        value: labColor
      });
    } else {
      this.props.setQuery({
        query: {
          "match_all": {}
        },
        value: ''
      });
    }
  }

  onFileChange(e) {
    if (!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];
    const formData = new FormData();
    
    formData.append(
      'file',
      file,
      file.name
    );

    this.setState({
      uploading: true
    });

    axios.post(
      window.gData.tceUrl,
      formData
    ).then((response) => {
      this.setState({
        uploading: false
      });
      this.debouncedHandleChangeColor({color: response.data.rgbHex});
    }).catch((error) => {
      console.warn(error);
      this.setState({
        uploading: false
      });
    });
  }

  render() {
    return (
      <div className="color-picker-wrapper">
      	{this.useCheckbox &&
	        <label className="mb-1">
	          <input
	            name="checkboxUseColorPicker"
	            type="checkbox"
	            checked={this.state.useColorPicker}
	            onChange={this.handleUseColorPickerChange}
	          />
	          <strong>Farbe</strong>
	        </label>
	    }
        {this.state.useColorPicker &&
          <div className="responsive">
            <HexColorPicker
              color={this.state.color}
              onChange={this.handleChangeColor}
            />
            <div id="color-form">
              <HexColorInput color={this.state.color} onChange={this.handleChangeColor} />
              <div id="color-file-upload-wrapper" className="file-upload-wrapper mt-1">
                <input type="file" onChange={this.onFileChange} />
                <button type="button"><div className={'lds-facebook' + (this.state.uploading ? ' active' : ' inactive')}><div></div><div></div><div></div></div><span>Bild hochladen</span></button>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default ColorPicker;