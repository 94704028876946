import React from "react";
import ReactDOM from "react-dom";
import lodash from 'lodash';
import DataItem from './DataItem';
import {
  ReactiveBase,
  ReactiveList,
  ResultList
} from '@appbaseio/reactivesearch';

const modalRoot = document.getElementById('modal-root');

class DetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');

    this.state = {
      imageFullscreen: false,
      showOcr: false,
      showFaces: (props.faceIds != null && props.faceIds.length > 0),
      showObjects: false
    }
  }

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.closeModal();
    }
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  openRelated = (itemToShow) => {
    const body = document.getElementById('overlayBody');

    if (body) {
      body.scrollTo(0, 0);
    }

    this.props.openModal(itemToShow);
  }

  render() {
    const { closeModal, item, faceIds, findFace } = this.props;

    return ReactDOM.createPortal(
      <div className="overlay">
        <div id="overlayBody" className="overlay-body">
          <div className={'row' + (this.state.imageFullscreen ? ' full-left' : '')}>
              <div className="col-50">
                <div className="detail-buttons">
                  <button
                    type="button"
                    className={this.state.imageFullscreen ? 'active' : 'inactive'}
                    onClick={
                      () => {
                        this.setState({
                          imageFullscreen: !this.state.imageFullscreen
                        });
                      }
                    }
                  >
                    Vollbild
                  </button>
                  <button
                    type="button"
                    className={this.state.showOcr ? 'active' : 'inactive'}
                    onClick={
                      () => {
                        this.setState({
                          showOcr: !this.state.showOcr,
                          showFaces: false,
                          showObjects: false
                        });
                      }
                    }
                  >
                    OCR Daten anzeigen
                  </button>
                  <button
                    type="button"
                    className={this.state.showFaces ? 'active' : 'inactive'}
                    onClick={
                      () => {
                        this.setState({
                          showOcr: false,
                          showFaces: !this.state.showFaces,
                          showObjects: false
                        });
                      }
                    }
                  >
                    Gesichter anzeigen
                  </button>
                  <button
                    type="button"
                    className={this.state.showObjects ? 'active' : 'inactive'}
                    onClick={
                      () => {
                        this.setState({
                          showOcr: false,
                          showFaces: false,
                          showObjects: !this.state.showObjects
                        });
                      }
                    }
                  >
                    Objekte anzeigen
                  </button>
                  <button
                    type="button"
                    onClick={
                      () => {
                        window.open(window.gData.imgDetailUrl + item.image.url.substring(item.image.url.lastIndexOf('/') + 1), '_blank');
                      }
                    }
                  >
                    Bild in neuem Tab öffnen
                  </button>
                </div>
                {(item.image && item.image.url) &&
                  <div className={'detail-image' + (this.state.showOcr ? ' show-ocr' : '') + (this.state.showFaces ? ' show-faces' : '') + (this.state.showObjects ? ' show-objects' : '')} target="_blank" rel="noreferrer">
                    {item.ocrTerms && 
                      <div className="bounding-boxes">
                        {item.ocrTerms.map(function(coordItem, coordIndex){
                          const left = (coordItem.x * 100) + '%';
                          const top = (coordItem.y * 100) + '%';
                          const width = (coordItem.w * 100) + '%';
                          const height = (coordItem.h * 100) + '%';
                          return <div key={'bounding-box' + coordIndex} className="bounding-box" style={{left: left, top: top, width: width, height: height}}><span>{coordItem.term}</span></div>
                        })}
                      </div>
                    }
                    {item.faces &&
                      <div className="faces-wrapper">
                        {item.faces.map(function(faceItem, faceIndex) {
                          const left = (faceItem.boundingBox.x1 * 100) + '%';
                          const top = (faceItem.boundingBox.y1 * 100) + '%';
                          const width = ((faceItem.boundingBox.x2 - faceItem.boundingBox.x1) * 100) + '%';
                          const height = ((faceItem.boundingBox.y2 - faceItem.boundingBox.y1) * 100) + '%';
                          return (
                            <div
                              key={'faces-box-' + faceIndex}
                              className={'faces-box' + (lodash.indexOf(faceIds, faceItem.id) > -1 ? ' active': '')}
                              style={{left: left, top: top, width: width, height: height}}
                              onClick={
                              	() => {
                                  findFace(faceItem);
                                }
                              }
                              ><span>{faceItem.confidence.toFixed(2)}</span></div>
                          )
                        })}
                        <svg width="100%" height="100%">
                          {item.faces.map(function(faceItem, faceIndex){
                            return (
                              <g key={'face-group-' + faceIndex}>
                                <circle cx={(faceItem.eyeLeft.x * 100) + '%'} cy={(faceItem.eyeLeft.y * 100) + '%'} r="4" fill="#00ff00" />
                                <circle cx={(faceItem.eyeRight.x * 100) + '%'} cy={(faceItem.eyeRight.y * 100) + '%'} r="4" fill="#00ff00" />
                                <circle cx={(faceItem.eyeNose.x * 100) + '%'} cy={(faceItem.eyeNose.y * 100) + '%'} r="4" fill="#00ff00" />
                                <circle cx={(faceItem.mouthLeft.x * 100) + '%'} cy={(faceItem.mouthLeft.y * 100) + '%'} r="4" fill="#00ff00" />
                                <circle cx={(faceItem.mouthRight.x * 100) + '%'} cy={(faceItem.mouthRight.y * 100) + '%'} r="4" fill="#00ff00" />
                                <line x1={(faceItem.eyeLeft.x * 100) + '%'} y1={(faceItem.eyeLeft.y * 100) + '%'} x2={(faceItem.eyeRight.x * 100) + '%'} y2={(faceItem.eyeRight.y * 100) + '%'} stroke="#fff" strokeWidth="1" />
                                <line x1={(faceItem.eyeLeft.x * 100) + '%'} y1={(faceItem.eyeLeft.y * 100) + '%'} x2={(faceItem.eyeNose.x * 100) + '%'} y2={(faceItem.eyeNose.y * 100) + '%'} stroke="#fff" strokeWidth="1" />
                                <line x1={(faceItem.eyeRight.x * 100) + '%'} y1={(faceItem.eyeRight.y * 100) + '%'} x2={(faceItem.eyeNose.x * 100) + '%'} y2={(faceItem.eyeNose.y * 100) + '%'} stroke="#fff" strokeWidth="1" />
                                <line x1={(faceItem.mouthLeft.x * 100) + '%'} y1={(faceItem.mouthLeft.y * 100) + '%'} x2={(faceItem.eyeNose.x * 100) + '%'} y2={(faceItem.eyeNose.y * 100) + '%'} stroke="#fff" strokeWidth="1" />
                                <line x1={(faceItem.mouthRight.x * 100) + '%'} y1={(faceItem.mouthRight.y * 100) + '%'} x2={(faceItem.eyeNose.x * 100) + '%'} y2={(faceItem.eyeNose.y * 100) + '%'} stroke="#fff" strokeWidth="1" />
                                <line x1={(faceItem.mouthLeft.x * 100) + '%'} y1={(faceItem.mouthLeft.y * 100) + '%'} x2={(faceItem.mouthRight.x * 100) + '%'} y2={(faceItem.mouthRight.y * 100) + '%'} stroke="#fff" strokeWidth="1" />
                              </g>
                            );
                          })}
                        </svg>
                      </div>
                    }
                    {item.objects &&
                      <div className="objects-wrapper">
                        {item.objects.map(function(objectsItem, objectIndex) {
                          const left = (objectsItem.boundingBox.x1 * 100) + '%';
                          const top = (objectsItem.boundingBox.y1 * 100) + '%';
                          const width = ((objectsItem.boundingBox.x2 - objectsItem.boundingBox.x1) * 100) + '%';
                          const height = ((objectsItem.boundingBox.y2 - objectsItem.boundingBox.y1) * 100) + '%';
                          return (
                            <div
                              key={'objects-box-' + objectIndex}
                              className={'objects-box'}
                              style={{left: left, top: top, width: width, height: height}}
                              ><span>{objectsItem.label} ({objectsItem.confidence.toFixed(2)})</span></div>
                          )
                        })}
                      </div>
                    }
                    <img className="detail-image" src={window.gData.imgDetailUrl + item.image.url.substring(item.image.url.lastIndexOf('/') + 1)} alt="" />
                  </div>
                }
              </div>
              <div className="col-50">
                <DataItem label="Titel" value={item.title} />
                <DataItem label="Sprache" value={item.language} />
                <DataItem label="Personen" value={item.mainPersonsSearch} />
                <DataItem label="Organisationen" value={item.mainCompaniesSearch} />
                <DataItem label="Verantwortlich" value={item.responsibility} />
                <DataItem label="Jahr / Datierung" value={item.creation.dateRaw} />
                <DataItem label="Verlage" value={item.creatorLocationsSearch} />
                {/* <DataItem
                  label="Verlage"
                  value={lodash.map(item.creation.creatorLocations, (item) => {
                    return item.creator + ', ' + item.location;
                  })} 
                /> */}
                <DataItem label="Art / Umfang / Format" value={item.physicalDescription} />
                <DataItem label="Anmerkung" value={item.generalNote} />
                <DataItem label="Angaben zum Inhalt" value={item.contentDescription} />
                <DataItem label="Mitwirkende Personen" value={item.contributingPersonsSearch} />
                <DataItem label="Mitwirkende Organisationen" value={item.contributingCompaniesSearch} />
                <DataItem label="Weitere Angaben" value={item.additionalPhysicalForm} />
                <DataItem label="Signatur" value={item.signaturesSearch} />
                <DataItem label="Fachgruppen" value={item.subjectGroup} />
                <DataItem label="Angaben zum Exemplar" value={item.specimenNote} />
                <DataItem label="ID-Nr." value={item.id} />
                <DataItem label="OCR Text" value={item.ocrText} />
                <DataItem label="Erkannte Gesichter" value={item.numFaces} />
                <DataItem label="Erkannte Objekte" value={item.numObjects} />

                <h2 className="more-header">Ähnliche Plakate (basierend auf Titel und Angaben zum Inhalt)</h2>
                <div className="more-wrapper">
                  <ReactiveBase
                    url={window.gData.esUrl}
                    app={window.gData.esIndexName}>
                    <ReactiveList
                      componentId="MoreLikeThisResults"
                      size={10}
                      pagination={false}
                      showResultStats={false}
                      dataField="title"
                      defaultQuery={() => {
                        return {
                          query: {
                            bool: {
                              must: {
                                more_like_this : {
                                  fields : ["title", "contentDescription"],
                                  like : item.title.replace(/(\[mark\]|(\[\/mark\]))/gi, '') + (item.contentDescription && (' ' + item.contentDescription.replace(/(\[mark\]|(\[\/mark\]))/gi, ''))), 
                                  min_term_freq : 1,
                                  min_doc_freq: 7,
                                  max_doc_freq: 75,
                                  max_query_terms : 25
                                }
                              },
                              must_not: {
                                term: { '_id': item._id }
                              }
                            }
                          }
                        }
                      }}
                      render={({ data, error, loading }) => (
                        <ReactiveList.ResultListWrapper>
                          {
                            data.map(item => (
                              <ResultList
                                key={item._id}
                                onClick={(e) => {
                                  this.openRelated(item);
                                }}
                                >
                                <ResultList.Image small={true} src={(item.thumbnail && item.thumbnail.url)?("img/thumbs/" + item.thumbnail.url.substring(item.thumbnail.url.lastIndexOf('/') + 1)):"img/no-thumb.png"}/>
                                <ResultList.Content>
                                  <ResultList.Title
                                      dangerouslySetInnerHTML={{
                                          __html: lodash.escape(item.title)
                                      }}
                                  />
                                  <ResultList.Description>
                                      <div>
                                          <div>{item.creation.date}</div>
                                          { item.physicalDescription &&
                                            <div>{item.physicalDescription.join('; ')}</div>
                                          }
                                      </div>
                                  </ResultList.Description>
                                </ResultList.Content>
                              </ResultList>
                            ))
                          }
                        </ReactiveList.ResultListWrapper>
                      )}
                    />
                  </ReactiveBase>
                </div>
              </div>
            </div>
        </div>
        <button className="overlay-close" onClick={closeModal}></button>
      </div>,
      this.el
    );
  }
}

export default DetailModal;