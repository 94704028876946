import React from "react";
import lodash from 'lodash';

class DataItem extends React.Component {
  render() {
    let { label, value } = this.props;
    if (lodash.isArray(value)) {
      value = value.join('; ');
    }

    if ((value && value.length > 0) || typeof value === 'number') {
      return (
        <div className="data-item">
          <label>{label}</label>
          <div dangerouslySetInnerHTML={{
            __html: lodash.escape(value).replaceAll('[mark]','<mark>').replaceAll('[/mark]','</mark>')
          }} />
        </div>
      );
    } else {
      return '';
    }
  }
}

export default DataItem;